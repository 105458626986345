@mixin translateX($translatex) {
	-moz-transform: translateX($translatex);
	-webkit-transform: translateX($translatex);
	-ms-transform: translateX($translatex);
	-o-transform: translateX($translatex);
	transform: translateX($translatex);
}
@mixin transition($transition) {
    -moz-transition:    all $transition ease;
    -o-transition:      all $transition ease;
    -webkit-transition: all $transition ease;
    -ms-transition: 		all $transition ease;
    transition:         all $transition ease;
}
@mixin inline-block() {
	display:-moz-inline-stack;
	display:inline-block;
	zoom:1;
	*display:inline;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin flex() {
	display: -webkit-box;      
  	display: -moz-box;         
  	display: -ms-flexbox;      
  	display: -webkit-flex;     
  	display: flex;             
}
@mixin flexwrap() {
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap; 
	-moz-flex-wrap: wrap; 
}

@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/fonts/icomoon.eot?srf3rx');
	src:url('../fonts/icomoon/fonts/icomoon.eot?srf3rx#iefix') format('embedded-opentype'),
		url('../fonts/icomoon/fonts/icomoon.ttf?srf3rx') format('truetype'),
		url('../fonts/icomoon/fonts/icomoon.woff?srf3rx') format('woff'),
		url('../fonts/icomoon/fonts/icomoon.svg?srf3rx#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin icomoon() {
	
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
}