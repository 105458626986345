#started {
	border-bottom: none;
	background: $brand-primary;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	float: left;
	width: 100%;
	.heading {
		margin-bottom: 0;
		h2 {
			color: $brand-white;
			margin-bottom: 20px !important;
		}
		p{
			color: rgba($brand-white,.8);
		}
	}

	.btn {
		height: 54px;
		border: none!important;
		background: $brand-white;
		color: $brand-primary;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 400;
		padding-left: 50px;
		padding-right: 50px;
	}
}