.progress-wrap{
	width: 100%;
	float: left;
	margin-bottom: 10px;
	h3{
		font-size: 16px;
		display: block;
		margin-bottom: 10px;
		float: left;
		width: 100%;
		.name-left{
			float: left;
		}
		.value-right{
			float: right;
		}
	}
}
.progress{
	width: 100%;
	float: left;
	box-shadow: none;
}
.progress-bar{
	background-color: $brand-bg-dark;
	display: block;
	box-shadow: none;
	&.progress-bar-1{
		background-color: #2980b9 !important;
	}
	&.progress-bar-2{
		background-color: #c0392b !important;
	}
	&.progress-bar-3{
		background-color: #d35400 !important;
	}
	&.progress-bar-4{
		background-color: #8e44ad !important;
	}
	&.progress-bar-5{
		background-color: #27ae60 !important;
	}
}

// CHART
.chart {
    width: 160px;
    height: 160px;
    margin: 0 auto 40px auto;
    position: relative;
    text-align: center;
    span {
        strong {
            display: block;
        }
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -30px;
      width: 100%;
 }
    canvas{
     position: absolute;
     left: 0;
     top: 0;
    }
}
