.blog{
	width: 100%;
	float: left;
	margin-bottom: 40px;
	@include media-breakpoint-down(sm) {
		width: 100%;
	}
	.blog-bg{
		display: block;
		width: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		position: relative;
		height: 300px;
		@include media-breakpoint-down(sm){
			height: 400px;
		}
		@include media-breakpoint-down(xs){
			height: 270px;
		}
	}
	.blog-text {
		margin-bottom: 30px;
		position: relative;
		width: 100%;
		padding: 30px;
		float: left;
		background: $brand-white;

		-webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.06);
		-moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.06);
		box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.06);

		@include transition(.3s);

		@include media-breakpoint-down(sm){
			padding: 10px;
		}

		span{
			display: inline-block;
			margin-bottom: 20px;
			font-size: 18px !important;
			color: rgba($brand-black,.3);
		}
		.stuff{
			margin: 0;
			padding: 0;
			border-top: 1px solid rgba($brand-black,.05);
			padding-top: 20px;
			li{
				list-style: none;
				display: inline-block;
				margin-left: 10px;
				@include media-breakpoint-down(sm){
					font-size: 14px;
				}
				&:first-child{
					margin-left: 0;
				}
				&:last-child{
					float: right;
					a{
						color: $brand-black;
					}
					i{
						padding-right: 0;
						padding-left: 10px;
					}
				}
				i{
					font-size: 14px;
					padding-right: 5px;
				}
			}
		}

		h3{
			font-size: 24px;
			margin-bottom: 20px;
			line-height: 1.5;
			a{
				color: rgba($brand-black,1);
			}
		}
	}
}