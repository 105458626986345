.btn {
	margin-right: 4px;
	margin-bottom: 4px;
	font-family: $font-primary;
	font-size: 16px;
	font-weight: 400;
	@include border-radius(30px);
	@include transition(.5s);
	padding: 8px 20px;
	&.btn-md {
		padding: 8px 20px!important;
	}
	&.btn-lg {
		padding: 18px 36px!important;
	}
	&:hover, &:active, &:focus {
		box-shadow: none!important;
		outline: none!important;
	}
}
.btn-primary {
	background: $brand-primary;
	color: $brand-white;
	border: 2px solid $brand-primary;
	&:hover, &:focus, &:active {
		background: lighten($brand-primary, 5%)!important;
		border-color: lighten($brand-primary, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-primary;
		border: 2px solid $brand-primary;
		&:hover, &:focus, &:active {
			background: $brand-primary;
			color: $brand-white;
		}
	}
}
.btn-success {
	background: $success;
	color: $brand-white;
	border: 2px solid $success;
	&:hover, &:focus, &:active {
		background: darken($success, 5%)!important;
		border-color: darken($success, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $success;
		border: 2px solid $success;
		&:hover, &:focus, &:active {
			background: $success;
			color: $brand-white;
		}
	}
}
.btn-info {
	background: $info;
	color: $brand-white;
	border: 2px solid $info;
	&:hover, &:focus, &:active {
		background: darken($info, 5%)!important;
		border-color: darken($info, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $info;
		border: 2px solid $info;
		&:hover, &:focus, &:active {
			background: $info;
			color: $brand-white;
		}
	}
}
.btn-warning {
	background: $warning;
	color: $brand-white;
	border: 2px solid $warning;
	&:hover, &:focus, &:active {
		background: darken($warning, 5%)!important;
		border-color: darken($warning, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $warning;
		border: 2px solid $warning;
		&:hover, &:focus, &:active {
			background: $warning;
			color: $brand-white;
		}
	}
}
.btn-danger {
	background: $danger;
	color: $brand-white;
	border: 2px solid $danger;
	&:hover, &:focus, &:active {
		background: darken($danger, 5%)!important;
		border-color: darken($danger, 5%)!important;
	}
	&.btn-outline {
		background: transparent;
		color: $danger;
		border: 2px solid $danger;
		&:hover, &:focus, &:active {
			background: $danger;
			color: $brand-white;
		}
	}
}

.btn-outline {
	background: none;
	border: 2px solid lighten($brand-black, 50%);
	font-size: 16px;
	@include transition(.3s);
	&:hover, &:focus, &:active {
		box-shadow: none;
	}
}

.btn.with-arrow {
	position: relative;
	@include transition(.3s);
	i {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		right: 0px;
		top: 50%;
		margin-top: -8px;
		@include transition(.2s);
	}
	&:hover {
		padding-right: 50px;
		i {
			color: $brand-white;
			right: 18px;
			visibility: visible;
			opacity: 1;
		}
	}
}