.timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
    &:before {
		top: 20px;
		bottom: 0;
		position: absolute;
		content: " ";
		width: 2px;
		background-color: lighten($brand-black,90%);
		left: 50%;
		margin-left: 0px;
		@include media-breakpoint-down(sm) {
			margin-left: -64px;
		}
		@include media-breakpoint-down(xs) {
			margin-left: -64px;
		}
	}
	.timeline-heading{
		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
		}
		>div{
			h3{
				display: inline-block;
				padding: 7px 15px;
				font-size: 18px;
				text-transform: uppercase;
				letter-spacing: 5px;
				font-weight: bold;
				background: lighten($brand-black,90%);
				@include border-radius(4px);
				@include media-breakpoint-down(sm) {
					float: left;
					margin-bottom: 0;
				}
			}
		}
	}
	> li{
		margin-bottom: 20px;
		position: relative;
		@include media-breakpoint-down(sm) {
			margin-bottom: 4em;	
		}
		&:before, &:after{
			content: " ";
			display: table;
		}
		&:after{
			clear: both;
		}
		> .timeline-panel {
			width: 45%;
			float: left;
			margin-bottom: 0;
			position: relative;
			@include border-radius(4px);
			@include media-breakpoint-down(sm) {
				width: 85% !important;
			}
			@include media-breakpoint-down(sm) {
				width: 75% !important;
				&:before{
					top: 30px;
				}
				&:after{
					top: 31px;
				}
			}
		}
		> .timeline-badge {
			color: #fff;
			width: 44px;
			height: 44px;
			line-height: 50px;
			font-size: 1.4em;
			text-align: center;
			position: absolute;
			top: 40px;
			left: 50%;
			margin-left: -21px;
			background-color: $brand-primary;
			z-index: 100;
			display: table;
			@include border-radius(50%);
			@include media-breakpoint-down(sm) {
				margin-left: -10px !important;
			}
			@include media-breakpoint-down(sm) {
				margin-left: -10px !important;
			}
			i{
				display: table-cell;
				vertical-align: middle;
				height: 44px;
				font-size: 18px;
			}
		}
		&.timeline-unverted{
			text-align: right;
			@include media-breakpoint-down(sm) {
				text-align: left;
			}
		}
		&.timeline-inverted {
			> .timeline-panel {
				float: right;
				&:before {
					border-left-width: 0;
					border-right-width: 15px;
					left: -15px;
					right: auto;
				}
				&:after {
					border-left-width: 0;
					border-right-width: 14px;
					left: -14px;
					right: auto;
				}
			}
		}
	}
}

.timeline-title {
    margin-top: 0;
 }

 .company{
	display: block;
	margin-bottom: 20px;
	font-size: 16px;
	font-weight: normal;
	color: lighten($brand-black, 75%);
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

@include media-breakpoint-down(md) {
	ul.timeline:before {
	  left: 90px;
	}

	ul.timeline > li > .timeline-panel {
	  width: calc(100% - 200px);
	  width: -moz-calc(100% - 200px);
	  width: -webkit-calc(100% - 200px);
	  // width: 40%;
	}

	ul.timeline > li > .timeline-badge {
	  left: 15px;
	  margin-left: 0;
	  top: 16px;
	}

	ul.timeline > li > .timeline-panel {
	  float: right;
	}

	ul.timeline > li > .timeline-panel:before {
	   border-left-width: 0;
	   border-right-width: 15px;
	   left: -15px;
	   right: auto;
	}

	ul.timeline > li > .timeline-panel:after {
	   border-left-width: 0;
	   border-right-width: 14px;
	   left: -14px;
	   right: auto;
	}
}