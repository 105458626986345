#about{
	.social-icons {
		margin: 0;
		padding: 0;
		li {
			padding: 0;
			list-style: none;
			@include inline-block;
			a {
				@include inline-block;
				color: $brand-white;
				background: $brand-bg-dark;
				padding: 10px 10px 2px 10px;
				@include border-radius(2px);
				i {
					font-size: 20px;
				}
				&:hover{
					background: $brand-primary;
				}
			}
		}
	}
}

.info{
	margin: 0;
	padding: 0;
	width: 90%;
	float: left;
	@include media-breakpoint-down(sm) {
		margin-bottom: 3em;
	}
	li{
		width: 100%;
		float: left;
		list-style: none;
		padding: 10px 0;
		&:first-child{
			padding-top: 0;
		}
		.first-block{
			width: 40%;
			display: inline-block;
			float: left;
			color: $brand-black;
			font-weight: bold;
		}
		.second-block{
			width: 60%;
			display: inline-block;
			color: rgba($brand-black,.5);
		}
	}
}