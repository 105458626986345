#features {
	background: $brand-primary;
	h2 {
		color: $brand-white;
	}
	.services-padding{
		padding: 7em 0;
	}
	.feature-left {
		margin-bottom: 40px;
		float: left;
		@include media-breakpoint-down(md) {
			margin-bottom: 30px;
		}
		.icon {
			display: table;
			text-align: center;
			width: 100px;
			height: 100px;
			margin: 0 auto;
			background: $brand-white;
			margin-bottom: 20px;
			@include border-radius(50%);
			i{
				font-size: 50px;
				display: table-cell;
				vertical-align: middle;
				height: 100px;
				color: $brand-primary !important;
			}
		}
		.feature-copy {
			width: 100%;
		}
		h3 {
			font-size: 24px;
			font-weight: 400;
			color: $brand-white;
		}
		p {
			font-size: 16px;
			color: rgba(255,255,255,.7);
			&:last-child {
				margin-bottom: 0;
			}
			a{
				color: $brand-black !important;
			}
		}
	}
}