$font-primary: 'Space Mono', Arial, serif;
$font-secondary: 'Kaushan Script', cursive;


// Overrides
$grid-gutter-width: 40px !default; 
$border-radius-base:  4px !default;
$padding-base-vertical: 14px !default;

$brand-primary: #FF9000 !default;

$brand-white: #fff;
$brand-black: #000;
$brand-darker: #444;
$brand-gray: #ccc;
$brand-lighter: #e9e9e9;
$brand-body-color: #818892;
$brand-selection-color: #f9f6f0;
$brand-overlay-color: #4c4a8a;
$brand-bg-color: $brand-white;

$brand-bg-dark: #2F3C4F;

$input-border-focus:  $brand-primary !default;
$form-group-margin-bottom:       30px !default;