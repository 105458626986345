#header,
.cover {
	background-position: 0px 0px;
	background-color: transparent;
	background-size: cover;
	background-attachment: fixed;
	position: relative;
	width: 100%;
	.overlay{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba($brand-primary,.9);
	}
	.display-t{
		width: 100%;
		display: table;
	}
	.display-tc {
		display: table-cell;
		vertical-align: middle;
		height: 100vh;
		h1, h2, h3 {
			margin: 0;
			padding: 0;
			color: rgba(255,255,255,1);
		}
		h1 {
			font-family: $font-secondary;
			margin-bottom: 30px;
			font-size: 50px;
			line-height: 1.3;
			font-weight: 300;
			-webkit-transform: rotate(-5deg);
			-moz-transform: rotate(-5deg);
			-ms-transform: rotate(-5deg);
			-o-transform: rotate(-5deg);
			transform: rotate(-5deg);
			span{
				padding: 4px 15px;
				position: relative;
				&:before{
					position: absolute;
					top: 40px;
					left: 0;
					width: 30px;
					height: 4px;
					content: '';
					background: $brand-white;
					margin-left: -30px;
				}
				&:after{
					position: absolute;
					top: 40px;
					right: 0;
					width: 30px;
					height: 4px;
					content: '';
					background: $brand-white;
					margin-right: -30px;
				}
			}
			@include media-breakpoint-down(sm) {
				font-size: 34px;
				span{
					&:before{
						top: 28px;
						width: 20px;
						height: 3px;
						margin-left: -15px;
					}
					&:after{
						top: 28px;
						width: 20px;
						height: 3px;
						margin-right: -15px;
					}
				}
			}
		}
		h2 {
			font-size: 20px;
			line-height: 1.5;
			margin-bottom: 30px;
		}
		h3{
			font-size: 16px;
			@include media-breakpoint-down(sm) {
				font-size: 14px;
			}
		}
		.profile-thumb{
			background-size: cover !important;
			background-position: center center;
			background-repeat: no-repeat;
			position: relative;
			height: 200px;
			width: 200px;
			margin: 0 auto;
			margin-bottom: 30px;
			// border: 1px solid rgba($brand-white,1);
			@include border-radius(50%);
		}
		.social-icons {
			li {
				a {
					color: $brand-white;
					i {
						font-size: 30px;
					}
				}
			}
		}
	}
}