body {
	font-family: $font-primary;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.7;
	color: darken($brand-white,70%);
	background: #fff;
}

#page {
	position: relative;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	@include transition(.5s);
	.offcanvas & {
		overflow: hidden;	
		position: absolute;
		
		&:after {
			@include transition(2s);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 101;
			background: rgba(0,0,0,.7);
			content: "";
		}
	}
}

a {
	color: $brand-primary;
	@include transition(.5s);
	&:hover, &:active, &:focus {
		color: $brand-primary;
		outline: none;
		text-decoration: none;
	}
}

p {
	margin-bottom: 30px;
}

h1, h2, h3, h4, h5, h6, figure {
	color: $brand-black;
	font-family: $font-primary;
	font-weight: 400;
	margin: 0 0 20px 0;
}

::-webkit-selection {
  color: $brand-white;
  background: $brand-primary;
}

::-moz-selection {
  color: $brand-white;
  background: $brand-primary;
}

::selection {
  color: $brand-white;
  background: $brand-primary;
}

.social-icons {
	margin: 0;
	padding: 0;
	li {
		margin: 0;
		padding: 0;
		list-style: none;
		@include inline-block;
		a {
			@include inline-block;
			color: $brand-primary;
			padding-left: 10px;
			padding-right: 10px;
			i {
				font-size: 20px;
			}
		}
	}
}

#about,
#resume,
#skills,
#started,
#work,
#blog,
#pricing,
#contact{
	padding: 7em 0;
	clear: both;
	@include media-breakpoint-down(sm) {
		padding: 3em 0;
	}
}

.heading {
	margin-bottom: 5em;
	&.heading-sm {
		margin-bottom: 2em;
	}
	h2 {
		font-size: 40px;
		margin-bottom: 20px;
		line-height: 1.5;
		color: $brand-black;
	}
	p {
		font-size: 18px;
		line-height: 1.5;
		color: #828282;
	}
	span{
		display: block;
		margin-bottom: 10px;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 2px;
	}
}

.gototop {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	@include transition(.5s);
	&.active {
		opacity: 1;
		visibility: visible;
	}
	a {
		width: 50px;
		height: 50px;
		display: table;
		background: rgba(0,0,0,.8);
		color: $brand-white;
		text-align: center;
		@include border-radius(50%);
		i {
			height: 50px;
			display: table-cell;
			vertical-align: middle;

		}
		&:hover, &:active, &:focus {
			text-decoration: none;
			outline: none;
		}
	}	
}
.progress{
	height: $progress-height;
}